/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import FormField from "../../../../../components/FormField";
import MDBox from "../../../../../components/MDBox";
import React from "react";
// Material Dashboard 2 PRO React components


function EditCityForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {name, state} = formField;
    const {
        name: nameV,
        state: stateV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={state.type}
                        label={state.label}
                        name={state.name}
                        value={stateV}
                        error={errors.state && touched.state}
                        success={stateV && stateV.length > 0 && !errors.state}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditCityForm;
