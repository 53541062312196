import React, {useState} from "react";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const usePayments = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: false,
        edit: false,
        delete: false,
    };

    return {
        actions, filters, setFilters
    }
};

export default usePayments;
