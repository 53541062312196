/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "sign-in-form",
  formField: {
    email: {
      name: "email",
      label: "Correo Electrónico",
      type: "email",
      placeholder: "ceo@mbr.com.mx",
      errorMsg: "El correo es obligatorio.",
      invalidMsg: "El correo no es válido.",
    },
    password: {
      name: "password",
      label: "Contraseña",
      type: "password",
      errorMsg: "La contraseña es obligatoria.",
    }
  },
};

export default form;
