
export const ENVIRONMENT = 'PRODUCTION';

const getAppUrl = () => {
    if(ENVIRONMENT === 'LOCAL')
        return ('http://192.168.100.2:8000');

    if(ENVIRONMENT === 'STAGING')
        return 'https://api.mbr.astwarehosting.com';

    if(ENVIRONMENT === 'PRODUCTION')
        return 'https://api.mbr.com.mx';
};

export const APP_URL = getAppUrl();
export const API_URL = APP_URL + '/api';

export const PUSHER_KEY = '';
