import React from "react";
import Card from "@mui/material/Card";
import Footer from "../../../components/Footer";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DataTable from "../../../components/DataTable/DataTable";
import {API_URL} from "../../../config";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import MDInput from "../../../components/MDInput";
import moment from "moment";
import useLeads from "./useLeads";

const Leads = () => {

    const {actions, filters, setFilters} = useLeads();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={2} pb={3}>
                <MDBox mb={3}>
                    <Card>
                        <DataTable
                            endpoint={`${API_URL}/inquiries`}
                            title="Prospectos"
                            description="Visualiza los prospectos generados a través de la app."
                            sortBy="created_at"
                            sortDirection="desc"
                            filters={filters}
                            actions={actions}
                            colData={[
                                {
                                    name: 'id',
                                    label: 'ID',
                                    options: {
                                        display: 'excluded',
                                        filter: false
                                    }
                                },
                                {
                                    name: 'user.name',
                                    label: 'Usuario',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => (
                                            <span style={{fontWeight: 500}}>{value}</span>)
                                    }
                                },
                                {
                                    name: 'type',
                                    label: 'Tipo'
                                },
                                {
                                    name: 'notes',
                                    label: 'Notas',
                                },
                                {
                                    name: 'date',
                                    label: 'Fecha Llamada',
                                },
                                {
                                    name: 'time',
                                    label: 'Hora Llamada',
                                },
                                {
                                    name: 'created_at',
                                    label: 'Creado',
                                    options: {
                                        customBodyRender: (value, tableMeta, updateValue) => value ? moment(value).format('YYYY-MM-DD') : ''
                                    }
                                }
                            ]}
                        />
                    </Card>
                </MDBox>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
};

export default Leads;
