/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-property-form",
  formField: {
    name: {
      name: "name",
      label: "Nombre",
      type: "text",
      errorMsg: "El nombre es obligatorio.",
    },
    total_price: {
      name: "total_price",
      label: "Precio",
      type: "number",
      errorMsg: "El precio es obligatorio.",
      invalidMsg: "Debe ser mayor a 0"
    },
    description: {
      name: "description",
      label: "Descripción",
      type: "text",
      errorMsg: "La descripción es obligatoria.",
    },
    suburb: {
      name: "suburb",
      label: "Colonia",
      type: "text",
      errorMsg: "La colonia es obligatoria.",
    },
    type: {
      name: "type",
      label: "Tipo de Propiedad",
      type: "select",
      errorMsg: "El tipo de propiedad es obligatorio.",
    },
    city_id: {
      name: "city_id",
      label: "Ciudad",
      type: "select",
      errorMsg: "La ciudad es obligatoria.",
    },
    beds: {
      name: "beds",
      label: "Recámaras",
      type: "number",
      errorMsg: "El campo es obligatorio.",
      invalidMsg: "Debe ser mayor o igual a 0"
    },
    baths: {
      name: "baths",
      label: "Baños",
      type: "number",
      errorMsg: "El campo es obligatorio.",
      invalidMsg: "Debe ser mayor o igual a 0"
    },
    parking_spaces: {
      name: "parking_spaces",
      label: "Estacionamientos",
      type: "number",
      errorMsg: "El campo es obligatorio.",
      invalidMsg: "Debe ser mayor o igual a 0"
    },
    featured_image: {
      name: "featured_image",
      label: "Imagen Destacada",
      type: "file",
      errorMsg: "La imagen destacada es obligatoria.",
    },
    images: {
      name: "images",
      label: "Imágenes Adicionales",
      type: "file",
    },
    amenities: {
      name: "amenities",
      label: "Amenidades",
      type: "select",
      errorMsg: "Las amenidades son obligatorias.",
    },
    payment_methods: {
      name: "payment_methods",
      label: "Métodos de Pago",
      type: "select",
      errorMsg: "Los métodos de pago son obligatorios.",
    },
  },
};

export default form;
