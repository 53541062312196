/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { name,tickets, description, short_description, ticket_price_subscription_id, ticket_price_id, ticket_price, special_price_subscription_id, special_price_id, special_price, end_date, image  },
} = checkout;

const validations = Yup.object().shape({
  [name.name]: Yup.string().required(name.errorMsg),
  [tickets.name]: Yup.number().required(tickets.errorMsg),
  [short_description.name]: Yup.string().required(short_description.errorMsg),
  [description.name]: Yup.string().required(description.errorMsg),
  [ticket_price.name]: Yup.number().required(ticket_price.errorMsg).moreThan(0, ticket_price.invalidMsg),
  [ticket_price_id.name]: Yup.string().required(ticket_price_id.errorMsg),
  [ticket_price_subscription_id.name]: Yup.string().required(ticket_price_subscription_id.errorMsg),
  [special_price.name]: Yup.number().required(ticket_price.errorMsg).moreThan(0, special_price.invalidMsg),
  [special_price_id.name]: Yup.string().required(special_price_id.errorMsg),
  [special_price_subscription_id.name]: Yup.string().required(special_price_subscription_id.errorMsg),
  [image.name]: Yup.string().required(image.errorMsg),
  [end_date.name]: Yup.date().required(end_date.errorMsg).min(new Date(), end_date.invalidMsg)
});

export default validations;
