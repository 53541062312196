import React, {useState} from "react";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useProperties = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate("/propiedades/nueva-propiedad"),
        edit: (id) => navigate(`/propiedades/${id}/editar-propiedad`),
        delete: true,
    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 1:
                return <Chip size="small" color="secondary" label="Activa"
                             variant="outlined"/>;
            case 0:
                return <Chip size="small" color="warning" label="Inactiva"
                             variant="outlined"/>;
        }
    };

    const renderTypeChip = (type) => {

        switch (type) {
            case 'Casa':
                return <Chip size="small" color="secondary" label="Casa"
                             variant="outlined"/>;
            case 'Departamento':
                return <Chip size="small" color="primary" label="Departamento"
                             variant="outlined"/>;
            case 'Local':
                return <Chip size="small" color="info" label="Local"
                             variant="outlined"/>;
            case 'Terreno':
                return <Chip size="small" color="info" label="Terreno"
                             variant="outlined"/>;
        }
    };

    return {
        actions, filters, setFilters, renderStatusChip, renderTypeChip
    }
};

export default useProperties;
