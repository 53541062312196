/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "tips-form",
  formField: {
    tables: {
      name: "tables",
      type: "array",
      errorMsg: "You must add at least one table.",
      formField: {
        table_id: {
          name: "table_id",
          type: "hidden"
        },
        name: {
          name: "name",
          type: "hidden"
        },
        tips_amount: {
          name: "tips_amount",
          label: "Tips",
          type: "number",
          errorMsg: "Tips is required.",
          variant: 'standard'
        }
      }
    }
  },
};

export default form;
