import React, {useState} from "react";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useAmenities = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate("/amenidades/nueva-amenidad"),
        edit: (id) => navigate(`/amenidades/${id}/editar-amenidad`),
        delete: true,
    };

    return {
        actions, filters, setFilters
    }
};

export default useAmenities;
