/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { name, description, total_price, suburb, featured_image, beds, baths, parking_spaces, type, city_id },
} = checkout;

const validations = Yup.object().shape({
  [name.name]: Yup.string().required(name.errorMsg),
  [description.name]: Yup.string().required(description.errorMsg),
  [total_price.name]: Yup.number().required(total_price.errorMsg).moreThan(0, total_price.invalidMsg),
  [suburb.name]: Yup.string().required(suburb.errorMsg),
  [featured_image.name]: Yup.string().required(featured_image.errorMsg),
  [beds.name]: Yup.number().required(beds.errorMsg).moreThan(-1, beds.invalidMsg),
  [baths.name]: Yup.number().required(baths.errorMsg).moreThan(-1, baths.invalidMsg),
  [parking_spaces.name]: Yup.number().required(parking_spaces.errorMsg).moreThan(-1, parking_spaces.invalidMsg),
  [type.name]: Yup.string().required(type.errorMsg),
  [city_id.name]: Yup.number().required(city_id.errorMsg),
});

export default validations;
