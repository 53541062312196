/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "recover-password-form",
  formField: {
    token: {
      name: 'token',
      type: 'hidden'
    },
    email: {
      name: "email",
      label: "Correo electrónico",
      type: "email",
      placeholder: "ceo@mbr.com.mx",
      errorMsg: "El correo electrónico es obligatorio.",
      invalidMsg: "El correo electrónico es inválido",
    },
    password: {
      name: "password",
      label: "Contraseña Nueva",
      type: "password",
      errorMsg: "La contraseña es obligatoria.",
      invalidMsg: "Debe contener por lo menos 8 caracteres",
    },
    password_confirmation: {
      name: "password_confirmation",
      label: "Confirmar Contraseña Nueva",
      type: "password",
      errorMsg: "Debes confirmar tu contraseña.",
      invalidMsg: "Las contraseñas no coinciden.",
    }
  },
};

export default form;
