/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    name,
      short_description,
      description,
      ticket_price,
      ticket_price_id,
      ticket_price_subscription_id,
      special_price,
      special_price_id,
      special_price_subscription_id,
    end_date,
      tickets,
      image
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [tickets.name]: "",
  [short_description.name]: "",
  [description.name]: "",
  [ticket_price.name]: "",
  [ticket_price_id.name]: "",
  [ticket_price_subscription_id.name]: "",
  [special_price.name]: "",
  [special_price_id.name]: "",
  [special_price_subscription_id.name]: "",
  [end_date.name]: "",
  [image.name]: "",
};

export default initialValues;
