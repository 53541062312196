import React, {useEffect, useState} from "react";
import axios from "axios";
import {formatFilters, isEmpty} from "../../helpers/helpers";
import MDButton from "../MDButton";
import CustomToolbar from "../CustomToolbar";
import CustomToolbarSelect from "../CustomToolbarSelect";
import {useSnackbar} from "../../context/SnackbarContext/SnackbarContext";

const useDataTable = ({endpoint, title, description, colData, sortBy, sortDirection, parentFilters, actions, filter, canSearch, loadAtStart, onSortChange = null, serverSide = true, forceLoad = false, rowsPP}) => {

    const { showSnackbar } = useSnackbar();

    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPP);
    const [sortOrder, setSortOrder] = useState({
        name: sortBy,
        direction: sortDirection,
    });

    const [isLoading, setIsLoading] = useState(loadAtStart);

    const [data, setData] = useState([]);

    const [columns, setColumns] = useState(colData);

    useEffect(() => {
        if(loadAtStart){
            getData(endpoint);
        }
    }, []);

    useEffect(() => {
        getData(endpoint);
    }, [forceLoad]);

    useEffect(() => {
        if(!isEmpty(parentFilters)){
            handleFilterSubmit(() => (parentFilters));
        }
    }, [parentFilters]);

    const getData = async (url) => {

        setIsLoading(true);

        try {

            const response = await loadData(url);

            setData(response.data);
            setCount(response.total);
            setPage(response.page);

        } catch (error) {

            if(error.response.data.message){

                showSnackbar(title, error.response.data.message, 'cancel', 'warning');

            } else {

                showSnackbar(title, 'Something went wrong loading the records.', 'cancel', 'warning');

            }

        }

        setIsLoading(false);
    };

    const loadData = async (url, config = {}) => {

        let fullConfig = {
            page,
            sortOrder,
            rowsPerPage,
            search,
            filters
        };

        if(config.page)
            fullConfig.page = config.page;

        if(config.sortOrder)
            fullConfig.sortOrder = config.sortOrder;

        if(config.rowsPerPage)
            fullConfig.rowsPerPage = config.rowsPerPage;

        if(config.search !== undefined)
            fullConfig.search = config.search;

        if(config.filters)
            fullConfig.filters = config.filters;

        const options = {
            params: {
                sort: fullConfig.sortOrder.name,
                direction: fullConfig.sortOrder.direction,
                page: fullConfig.page,
                per_page: fullConfig.rowsPerPage,
                search: fullConfig.search ? fullConfig.search : undefined,
                filters: fullConfig.filters ? JSON.stringify(fullConfig.filters) : undefined,
            }
        };

        const response = await axios.get(url, options);

        return {
            data: response.data.data,
            total: response.data.total,
            page: response.data.current_page,
        };

    };

    const sort = async (sortOrder) => {
        setIsLoading(true);

        let options = {
            sortOrder,
        };

        const response = await loadData(endpoint, options);

        if(onSortChange)
            onSortChange(sortOrder);

        setData(response.data);
        setSortOrder(sortOrder);

        setIsLoading(false);
    };

    const changePage = async (page) => {
        setIsLoading(true);

        let options = {
            page: page + 1
        };

        const response = await loadData(endpoint, options);

        setPage(response.page);
        setData(response.data);

        setIsLoading(false);
    };

    const changeRowsPerPage = async (rowsPerPage) => {
        setIsLoading(true);

        let options = {
            rowsPerPage
        };

        const response = await loadData(endpoint, options);

        setRowsPerPage(rowsPerPage);
        setData(response.data);

        setIsLoading(false);
    };

    const handleSearch = async (search) => {

        setIsLoading(true);

        let options = {
            search
        };

        const response = await loadData(endpoint, options);

        setSearch(search);
        setCount(response.total);
        setPage(response.page);
        setData(response.data);

        setIsLoading(false);

    };

    const handleFilterSubmit = async (applyFilters) => {

        let filterList = formatFilters(applyFilters(), columns);

        setIsLoading(true);

        let options = {
            filters: filterList,
        };

        const response = await loadData(endpoint, options);

        setCount(response.total);
        setPage(response.page);
        setData(response.data);
        setFilters(filterList);

        setIsLoading(false);

    };

    const handleDeleteClick = async (ids) => {

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = ids.length;

            // Por cada registro seleccionado
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${endpoint}/${ids[i]}`);

            }

            showSnackbar(title, count === 1 ? `1 registro actualizado` : `${count} registros actualizados`, 'notifications', 'secondary')

            const response = await loadData(endpoint);

            setCount(response.total);
            setPage(response.page);
            setData(response.data);

        } catch (error) {

            if(error.response.data.message){

                showSnackbar(title, error.response.data.message, 'cancel', 'warning');

            } else {

                showSnackbar(title, 'Algo salió mal activando o desactivando uno o mas registros.', 'cancel', 'warning');

            }
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const customCallback = async () => {
        const response = await loadData(endpoint);

        setCount(response.total);
        setPage(response.page);
        setData(response.data);
    };


    const options = {
        elevation: 0,
        responsive: 'vertical',
        serverSide: serverSide,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 25, 50],
        sortOrder: sortOrder,
        download: false,
        print: false,
        enableNestedDataAccess: '.',
        search: canSearch,
        filter: filter,
        filterType: 'textField',
        confirmFilters: true,
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
                <div style={{marginTop: '40px'}}>
                    <MDButton variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>Aplicar Filtros</MDButton>
                </div>
            );
        },
        customToolbar: () => {
            return (
                <CustomToolbar canAdd={actions.add !== false} handleNewClick={actions.add} customActions={actions.custom && actions.custom.filter(action => action.type !== 'select')} />
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect
                selectedRows={selectedRows}
                displayData={displayData}
                setSelectedRows={setSelectedRows}
                canEdit={actions.edit !== false}
                handleEditClick={actions.edit}
                canDelete={actions.delete}
                handleDeleteClick={actions.delete === true ? handleDeleteClick : actions.delete}
                customActions={actions.custom && actions.custom.filter(action => action.type === 'select')}
                customCallback={customCallback}
            />
        ),
        onFilterChange: (column, filterList, type) => {
            if (type === 'chip') {
                let newFilters = () => (filterList);
                handleFilterSubmit(newFilters);
            }
        },
        onSearchChange: handleSearch,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    changePage(tableState.page);
                    break;
                case 'sort':
                    sort(tableState.sortOrder);
                    break;
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage);
                    break;
            }
        },
        textLabels: {
            body: {
                noMatch: "No records found",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort by ${column.label}`
            },
            pagination: {
                next: "Next Page",
                previous: "Previous Page",
                rowsPerPage: "Rows per page:",
                displayRows: "of",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
            },
            filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
            },
            viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Columns",
            },
            selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
            },
        }
    };

    const renderTitle = () => {
        return title;
    };

    const renderIntro = () => {
        return description;
    };

    return {
        isLoading,
        data,
        columns,
        options,
        renderIntro,
        renderTitle,
        handleFilterSubmit,
        forceLoad
    }

};

export default useDataTable;
