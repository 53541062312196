/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-raffle-form",
  formField: {
    name: {
      name: "name",
      label: "Nombre",
      type: "text",
      errorMsg: "El nombre es obligatorio.",
    },
    tickets: {
      name: "tickets",
      label: "Boletos",
      type: "number",
      errorMsg: "La cantidad de boletos es obligatoria.",
    },
    short_description: {
      name: "short_description",
      label: "Descripción Breve",
      type: "text",
      errorMsg: "La descripción breve es obligatoria.",
    },
    description: {
      name: "description",
      label: "Descripción",
      type: "text",
      errorMsg: "La descripción es obligatoria.",
    },
    ticket_price: {
      name: "ticket_price",
      label: "Precio (1 Boleto)",
      type: "number",
      errorMsg: "El precio es obligatorio.",
      invalidMsg: "Debe ser mayor a 0"
    },
    ticket_price_id: {
      name: "ticket_price_id",
      label: "ID Precio (Stripe)",
      type: "text",
      errorMsg: "El ID del precio es obligatorio.",
    },
    ticket_price_subscription_id: {
      name: "ticket_price_subscription_id",
      label: "ID Precio Subscripción (Stripe)",
      type: "text",
      errorMsg: "El ID del precio subscripción es obligatorio.",
    },
    special_price: {
      name: "special_price",
      label: "Precio Especial (3 Boletos)",
      type: "number",
      errorMsg: "El precio especial es obligatorio.",
      invalidMsg: "Debe ser mayor a 0"
    },
    special_price_id: {
      name: "special_price_id",
      label: "ID Precio Especial (Stripe)",
      type: "text",
      errorMsg: "El ID del precio especial es obligatorio.",
    },
    special_price_subscription_id: {
      name: "special_price_subscription_id",
      label: "ID Precio Especial Subscripción (Stripe)",
      type: "text",
      errorMsg: "El ID del precio especial subscripción es obligatorio.",
    },
    image: {
      name: "image",
      label: "Imagen Destacada",
      type: "file",
      errorMsg: "La imagen destacada es obligatoria.",
    },
    end_date: {
      name: "end_date",
      label: "Fecha de Finalización",
      type: "date",
      errorMsg: "La fecha de finalización es obligatoria.",
      invalidMsg: "Debe ser una fecha en el futuro."
    },
  },
};

export default form;
