import React, {useEffect, useState} from "react";
import {useDropzone} from 'react-dropzone';
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import Icon from "@mui/material/Icon";
import MDTypography from "../MDTypography";
import Tooltip from "@mui/material/Tooltip";
import {APP_URL} from "../../config";

const useStyles = makeStyles(theme => ({
    dropZone: {
        flex: 1,
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        borderWidth: 1,
        borderRadius: '0.25rem',
        borderColor: 'rgb(210, 214, 218)',
        borderStyle: 'solid',
        outline: 'none',
        transition: 'border .14s ease-in-out',
        '&:hover':{
            borderColor: '#004d40',
        },
        '&:hover $dropzoneIcon': {
            color: '#004d40'
        }
    },
    fileError: {
        borderColor: '#EF5350',
        color: '#EF5350'
    },
    fileSuccess: {
        borderColor: '#628439',
        color: '#628439'
    },
    dropzoneIcon: {
        color: '#adb5bd',
        transition: 'color .14s ease-in-out',
    },
    iconError: {
        color: '#EF5350'
    },
    iconSuccess: {
        color: '#628439'
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 0,
        overflow: 'hidden',
        '&:hover $imageRemove': {
            opacity: 1
        }
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    },
    imageRemove: {
        position: 'absolute',
        display: 'flex',
        opacity: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transition: 'opacity 300ms'
    },
    removeIcon: {
        flex: 1,
        cursor: 'pointer'
    }
}));

const Index = ({ form, field, value, multiple = false, maxSize = 5000000, accept, fileType, ...rest }) => {

    const classes = useStyles();

    const [fileErrorMessage, setFileErrorMessage] = useState('');
    const [fileName, setFileName] = useState(multiple ? [] : '');

    const { getRootProps, getInputProps } = useDropzone({
        accept,
        maxSize,
        multiple,
        noDrag: false,
        onDrop: acceptedFiles => {

            const files = acceptedFiles.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );

            // setFiles(files);
            setFileErrorMessage('');

            if(!multiple) {
                setFileName(acceptedFiles[0].name);
                form.setFieldValue(field.name, files[0]);
            }else {
                setFileName(acceptedFiles.map(f => f.name));
                form.setFieldValue(field.name, files);
            }

        },
        onDropRejected: (files, event) => {
            handleFileError(files)
        }
    });

    // Función que se ejecuta cuando se selecciona un archivo inválido
    const handleFileError = (rejectedFiles) => {

        switch (rejectedFiles[0].errors[0].code) {
            case 'file-too-large':
                setFileErrorMessage('El archivo pesa mas que el límite de 500KB');
                break;
            case 'file-invalid-type':
                setFileErrorMessage('El tipo de archivo no es válido');
                break;
            default:
                setFileErrorMessage('Ocurrió un error desconocido');
                break;
        }

    };

    return (
        <div>
            <section className={ clsx(classes.dropZone, fileErrorMessage !== '' && classes.fileError, value && classes.fileSuccess) } {...getRootProps()}>

                <Icon className={ clsx(classes.dropzoneIcon, fileErrorMessage && classes.iconError, value && classes.iconSuccess) } fontSize="large">{fileType === 'image' ? 'image' : 'article'}</Icon>
                <input {...getInputProps()} />
                {
                    fileErrorMessage !== '' ?
                        <MDTypography variant="button" color="error">{fileErrorMessage}</MDTypography> :
                        value ?
                            <MDTypography variant="button">{typeof value === 'string' ? value : (multiple ? fileName.join(', ') : fileName) }</MDTypography> :
                            <MDTypography variant="button">{`Arrastra o da click para seleccionar ${fileType == 'image' ? 'una imagen' : 'un archivo'}`}</MDTypography>
                }
            </section>
        </div>
    );
};

export default Index;
