/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 PRO React layouts
// import Analytics from "layouts/dashboards/analytics";
import Settings from "layouts/pages/account/settings";
// Material Dashboard 2 PRO React components
// @mui icons
import Icon from "@mui/material/Icon";
// Images
import Users from "./layouts/users/view/Users";
import Payments from "./layouts/payments/view/Payments";
import Leads from "./layouts/leads/view/Leads";
import Cities from "./layouts/cities/view/Cities";
import Amenities from "./layouts/amenities/view/Amenities";
import Properties from "./layouts/properties/view/Properties";
import Raffles from "./layouts/raffles/view/Raffles";

export const adminRoutes = [
  // {
  //   type: "collapse",
  //   name: "Brooklyn Alice",
  //   key: "brooklyn-alice",
  //   icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  //   collapse: [
  //     {
  //       name: "My Profile",
  //       key: "my-profile",
  //       route: "/pages/profile/profile-overview",
  //       component: <ProfileOverview />,
  //     },
  //     {
  //       name: "Settings",
  //       key: "profile-settings",
  //       route: "/pages/account/settings",
  //       component: <Settings />,
  //     },
  //   ],
  // },
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Páginas", key: "title-pages" },
  {
    type: "collapse",
    name: "Usuarios",
    key: "usuarios",
    icon: <Icon fontSize="medium">person</Icon>,
    route: "/usuarios",
    component: <Users/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Pagos",
    key: "pagos",
    icon: <Icon fontSize="medium">payment</Icon>,
    route: "/pagos",
    component: <Payments/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Prospectos",
    key: "prospectos",
    icon: <Icon fontSize="medium">assignment_ind</Icon>,
    route: "/prospectos",
    component: <Leads/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Propiedades",
    key: "propiedades",
    icon: <Icon fontSize="medium">house</Icon>,
    route: "/propiedades",
    component: <Properties/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sorteos",
    key: "sorteos",
    icon: <Icon fontSize="medium">local_activity</Icon>,
    route: "/sorteos",
    component: <Raffles/>,
    noCollapse: true,
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Data", key: "title-docs" },
  {
    type: "collapse",
    name: "Ciudades",
    key: "ciudades",
    icon: <Icon fontSize="medium">apartment</Icon>,
    route: "/ciudades",
    component: <Cities/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Amenidades",
    key: "amenidades",
    icon: <Icon fontSize="medium">lightbulbs</Icon>,
    route: "/amenidades",
    component: <Amenities/>,
    noCollapse: true,
  },
];
