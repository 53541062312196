import form from "./schemas/form";
import validations from "./schemas/validations";
import {useEffect, useState} from "react";
import initialValues from "./schemas/initialValues";
import axios from "axios";
import {API_URL} from "../../../config";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import {useParams} from 'react-router-dom';
import jsonToFormData from "json-form-data";

const useEditProperty = () => {

    const { id } = useParams();

    const {formId, formField} = form;
    const currentValidation = validations;

    const [values, setValues] = useState(initialValues);

    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [cities, setCities] = useState([]);
    const [amenities, setAmenities] = useState([]);


    useEffect(() => {

        const init = async () => {

            setIsLoading(true);

            await loadRecord();
            await loadCities();
            await loadAmenities();

            setIsLoading(false);
        };

        init();

    }, []);

    const loadRecord = async () => {

        try {

            const response = await axios.get(`${API_URL}/properties/${id}`);

            let data = response.data;

            data.amenities = data.amenities.map(amen => amen.id);

            setValues(data);

        } catch (error) {

            setSnackbarConfig({
                message: "Algo salió mal cargando la propiedad",
                icon: 'cancel',
                title: 'Propiedades',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadCities = async () => {

        try {

            const response = await axios.get(`${API_URL}/cities`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setCities([{id: -1, label: 'Elige una ciudad'}, ...response.data.map(city => ({id: city.id, label: city.name}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Ocurrió un error cargando las ciudades",
                icon: 'cancel',
                title: 'Ciudades',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadAmenities = async () => {

        try {

            const response = await axios.get(`${API_URL}/amenities`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setAmenities(response.data.map(amenity => ({id: amenity.id, label: amenity.name})));

        } catch (error) {

            setSnackbarConfig({
                message: "Ocurrió un error cargando las amenidades",
                icon: 'cancel',
                title: 'Amenidades',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const handleSubmit = async (values, actions) => {

        try {


            let options = {
                initialFormData: new FormData(),
                showLeafArrayIndexes: true,
                includeNullValues: true
            };

            let data = {...values};

            data._method = 'PUT';

            if(typeof data.featured_image === 'string')
                delete data.featured_image;

            const formData = jsonToFormData(data, options);

            // Enviar datos a API
            await axios.post(
                `${API_URL}/properties/${id}`,
                formData
            );

            setSnackbarConfig({
                message: 'Propiedad actualizada exitosamente',
                icon: 'notifications',
                title: 'Propiedadades',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Propiedadades',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        cities,
        amenities,
        formId,
        formField,
        currentValidation,
        values,
        isLoading,
        snackbarConfig,
        show,
        toggleSnackbar,
        handleSubmit
    }
};

export default useEditProperty;
