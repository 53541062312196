/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
// Material Dashboard 2 PRO React components


function NewRaffleForm({formData, cities, amenitiesOptions}) {

    const {formField, values, errors, touched} = formData;

    const {name, tickets, short_description, description, ticket_price, ticket_price_id, ticket_price_subscription_id, special_price, special_price_id, special_price_subscription_id, end_date, image} = formField;
    const {
        name: nameV,
        short_description: shortDescriptionV,
        description: descriptionV,
        ticket_price: ticketPriceV,
        ticket_price_id: ticketPriceIdV,
        ticket_price_subscription_id: ticketPriceSubscriptionIdV,
        special_price: specialPriceV,
        special_price_id: specialPriceIdV,
        special_price_subscription_id: specialPriceSubscriptionIdV,
        end_date: endDateV,
        image: imageV,
        tickets: ticketsV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={tickets.type}
                        label={tickets.label}
                        name={tickets.name}
                        value={ticketsV}
                        error={errors.tickets && touched.tickets}
                        success={ticketsV && ticketsV.length > 0 && !errors.tickets}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={end_date.type}
                        label={end_date.label}
                        name={end_date.name}
                        value={endDateV}
                        options={{enableTime: false, disableMobile: true, dateFormat: 'Y-m-d', minDate: new Date()}}
                        placeholder={end_date.placeholder}
                        error={errors.end_date && touched.end_date}
                        success={endDateV && !errors.end_date}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={short_description.type}
                        label={short_description.label}
                        name={short_description.name}
                        value={shortDescriptionV}
                        error={errors.short_description && touched.short_description}
                        success={shortDescriptionV && shortDescriptionV.length > 0 && !errors.short_description}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={description.type}
                        label={description.label}
                        name={description.name}
                        value={descriptionV}
                        rows={4}
                        multiline={true}
                        error={errors.description && touched.description}
                        success={descriptionV && descriptionV.length > 0 && !errors.description}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={ticket_price.type}
                        label={ticket_price.label}
                        name={ticket_price.name}
                        value={ticketPriceV}
                        error={errors.ticket_price && touched.ticket_price}
                        success={ticketPriceV && ticketPriceV.length > 0 && !errors.ticket_price}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={ticket_price_id.type}
                        label={ticket_price_id.label}
                        name={ticket_price_id.name}
                        value={ticketPriceIdV}
                        error={errors.ticket_price_id && touched.ticket_price_id}
                        success={ticketPriceIdV && ticketPriceIdV.length > 0 && !errors.ticket_price_id}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={ticket_price_subscription_id.type}
                        label={ticket_price_subscription_id.label}
                        name={ticket_price_subscription_id.name}
                        value={ticketPriceSubscriptionIdV}
                        error={errors.ticket_price_subscription_id && touched.ticket_price_subscription_id}
                        success={ticketPriceSubscriptionIdV && ticketPriceSubscriptionIdV.length > 0 && !errors.ticket_price_subscription_id}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={special_price.type}
                        label={special_price.label}
                        name={special_price.name}
                        value={specialPriceV}
                        error={errors.special_price && touched.special_price}
                        success={specialPriceV && specialPriceV.length > 0 && !errors.special_price}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={special_price_id.type}
                        label={special_price_id.label}
                        name={special_price_id.name}
                        value={specialPriceIdV}
                        error={errors.special_price_id && touched.special_price_id}
                        success={specialPriceIdV && specialPriceIdV.length > 0 && !errors.special_price_id}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={special_price_subscription_id.type}
                        label={special_price_subscription_id.label}
                        name={special_price_subscription_id.name}
                        value={specialPriceSubscriptionIdV}
                        error={errors.special_price_subscription_id && touched.special_price_subscription_id}
                        success={specialPriceSubscriptionIdV && specialPriceSubscriptionIdV.length > 0 && !errors.special_price_subscription_id}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={image.type}
                        label={image.label}
                        name={image.name}
                        value={imageV}
                        accept={{
                            'image/png': ['.png']
                        }}
                        fileType="image"
                        error={errors.image && touched.image}
                        success={imageV && imageV.length > 0 && !errors.image}
                    />
                </Grid>
            </Grid>
            {
                JSON.stringify(values, 0, 2)
            }
        </MDBox>
    );
}

export default NewRaffleForm;
