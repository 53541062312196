/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import FormField from "../../../../../components/FormField";
import MDBox from "../../../../../components/MDBox";
import React from "react";
// Material Dashboard 2 PRO React components


function EditAmenityForm({formData}) {

    const {formField, values, errors, touched} = formData;

    const {name, icon} = formField;
    const {
        name: nameV,
        icon: iconV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={icon.type}
                        label={icon.label}
                        name={icon.name}
                        value={iconV}
                        accept={{
                            'image/png': ['.png']
                        }}
                        fileType="image"
                        error={errors.icon && touched.icon}
                        success={iconV && iconV.length > 0 && !errors.icon}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditAmenityForm;
