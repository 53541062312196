/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
// Material Dashboard 2 PRO React components


function NewPropertyForm({formData, cities, amenitiesOptions, paymentMethodsOptions}) {

    const {formField, values, errors, touched} = formData;

    const {name, description, total_price, suburb, featured_image, images, beds, baths, parking_spaces, type, city_id, amenities, payment_methods} = formField;
    const {
        name: nameV,
        description: descriptionV,
        total_price: totalPriceV,
        suburb: suburbV,
        featured_image: featuredImageV,
        images: imagesV,
        beds: bedsV,
        baths: bathsV,
        parking_spaces: parkingSpacesV,
        type: typeV,
        city_id: cityIdV,
        amenities: amenitiesV,
        payment_methods: paymentMethodsV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={total_price.type}
                        label={total_price.label}
                        name={total_price.name}
                        value={totalPriceV}
                        error={errors.total_price && touched.total_price}
                        success={totalPriceV && totalPriceV.length > 0 && !errors.total_price}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={suburb.type}
                        label={suburb.label}
                        name={suburb.name}
                        value={suburbV}
                        error={errors.suburb && touched.suburb}
                        success={suburbV && suburbV.length > 0 && !errors.suburb}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={city_id.type}
                        label={city_id.label}
                        name={city_id.name}
                        value={cityIdV}
                        options={cities}
                        error={errors.city_id && touched.city_id}
                        success={cityIdV && cityIdV.length > 0 && !errors.city_id}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={type.type}
                        label={type.label}
                        name={type.name}
                        value={typeV}
                        options={[
                            {
                                id: -1, label: 'Elige el tipo de propiedad'
                            },
                            {
                                id: 'Casa', label: 'Casa'
                            },
                            {
                                id: 'Departamento', label: 'Departamento'
                            },
                            {
                                id: 'Local', label: 'Local'
                            },
                            {
                                id: 'Terreno', label: 'Terreno'
                            }
                        ]}
                        error={errors.type && touched.type}
                        success={typeV && typeV.length > 0 && !errors.type}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={description.type}
                        label={description.label}
                        name={description.name}
                        value={descriptionV}
                        multiline={true}
                        rows={3}
                        error={errors.description && touched.description}
                        success={descriptionV && descriptionV.length > 0 && !errors.description}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={featured_image.type}
                        label={featured_image.label}
                        name={featured_image.name}
                        value={featuredImageV}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpg': ['.jpg'],
                            'image/jpeg': ['.jpeg'],
                        }}
                        fileType="image"
                        error={errors.featured_image && touched.featured_image}
                        success={featuredImageV && featuredImageV.length > 0 && !errors.featured_image}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={images.type}
                        label={images.label}
                        name={images.name}
                        value={imagesV}
                        multiple={true}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpg': ['.jpg'],
                            'image/jpeg': ['.jpeg'],
                        }}
                        fileType="image"
                        error={errors.images && touched.images}
                        success={imagesV && imagesV.length > 0 && !errors.images}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={amenities.type}
                        label={amenities.label}
                        name={amenities.name}
                        value={amenitiesV}
                        multiple={true}
                        options={amenitiesOptions}
                        error={errors.amenities && touched.amenities}
                        success={amenitiesV && amenitiesV.length > 0 && !errors.amenities}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={payment_methods.type}
                        label={payment_methods.label}
                        name={payment_methods.name}
                        value={paymentMethodsV}
                        multiple={true}
                        options={paymentMethodsOptions}
                        error={errors.payment_methods && touched.payment_methods}
                        success={paymentMethodsV && paymentMethodsV.length > 0 && !errors.payment_methods}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={beds.type}
                        label={beds.label}
                        name={beds.name}
                        value={bedsV}
                        error={errors.beds && touched.beds}
                        success={bedsV && bedsV.length > 0 && !errors.beds}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={baths.type}
                        label={baths.label}
                        name={baths.name}
                        value={bathsV}
                        error={errors.baths && touched.baths}
                        success={bathsV && bathsV.length > 0 && !errors.baths}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={parking_spaces.type}
                        label={parking_spaces.label}
                        name={parking_spaces.name}
                        value={parkingSpacesV}
                        error={errors.parking_spaces && touched.parking_spaces}
                        success={parkingSpacesV && parkingSpacesV.length > 0 && !errors.parking_spaces}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewPropertyForm;
