import React, {useState} from "react";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useRaffles = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate("/sorteos/nuevo-sorteo"),
        edit: (id) => navigate(`/sorteos/${id}/editar-sorteo`),
        delete: true,
    };

    return {
        actions, filters, setFilters
    }
};

export default useRaffles;
