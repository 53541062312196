import form from "./schemas/form";
import validations from "./schemas/validations";
import {useState, useEffect} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import jsonToFormData from "json-form-data";

const useNewProperty = () => {

    const {formId, formField} = form;
    const currentValidation = validations;

    const [isLoading, setIsLoading] = useState(true);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const [cities, setCities] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {

        const init = async () => {
            setIsLoading(true);
            await loadCities();
            await loadAmenities();
            await loadPaymentMethods();
            setIsLoading(false);
        };

        init();

    }, []);

    const loadCities = async () => {

        try {

            const response = await axios.get(`${API_URL}/cities`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setCities([{id: -1, label: 'Elige una ciudad'}, ...response.data.map(city => ({id: city.id, label: city.name}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Ocurrió un error cargando las ciudades",
                icon: 'cancel',
                title: 'Ciudades',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadAmenities = async () => {

        try {

            const response = await axios.get(`${API_URL}/amenities`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setAmenities(response.data.map(amenity => ({id: amenity.id, label: amenity.name})));

        } catch (error) {

            setSnackbarConfig({
                message: "Ocurrió un error cargando las amenidades",
                icon: 'cancel',
                title: 'Amenidades',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const loadPaymentMethods = async () => {

        try {

            const response = await axios.get(`${API_URL}/payment_methods`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setPaymentMethods(response.data.map(paymentM => ({id: paymentM.id, label: paymentM.name})));

        } catch (error) {

            setSnackbarConfig({
                message: "Ocurrió un error cargando los métodos de pago",
                icon: 'cancel',
                title: 'Métodos de Pago',
                color: 'warning'
            });

            setShow(true);

        }

    };

    const handleSubmit = async (values, actions) => {

        try {

            let options = {
                initialFormData: new FormData(),
                showLeafArrayIndexes: true,
                includeNullValues: true
            };

            let data = {...values};

            const formData = jsonToFormData(data, options);

            // Enviar datos a API
            await axios.post(
                `${API_URL}/properties`,
                formData
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'Propiedad registrada exitosamente',
                icon: 'notifications',
                title: 'Propiedades',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Propiedades',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        cities,
        amenities,
        paymentMethods,
        currentValidation,
        formId,
        formField,
        isLoading,
        show,
        snackbarConfig,
        handleSubmit,
        toggleSnackbar
    };
};

export default useNewProperty;
