/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    name,
      description,
      total_price,
      suburb,
      featured_image,
      beds,
      baths,
      parking_spaces,
      type,
      city_id,
      images,
      amenities,
      payment_methods
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [description.name]: "",
  [total_price.name]: "",
  [suburb.name]: "",
  [featured_image.name]: "",
  [beds.name]: "",
  [baths.name]: "",
  [parking_spaces.name]: "",
  [type.name]: -1,
  [city_id.name]: -1,
  [images.name]: '',
  [amenities.name]: [],
  [payment_methods.name]: []
};

export default initialValues;
